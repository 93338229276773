// const url = "https://nodepune-classifiedads.mobiloitte.io/api/v1"; // staging url
const url = "https://node.fanos.one/api/v1"; // live url
const user = `${url}/user`;

const ApiConfig = {
  login: `${user}/login`,
  signup: `${user}/signup`,
  forgot: `${user}/forgotPassword`,
  verifyOTP: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,
  resetPassword: `${user}/resetPassword`,
  viewMyProfile: `${user}/viewMyProfile`,

  getAddress: `${url}/addresses/getAddresses`,
  addAddress: `${url}/addresses/createAddress`,
  editAddress: `${url}/addresses/updateAddress`,
  deleteAddress: `${url}/addresses/deleteAddress`,
  getPopularProduct: `${url}/user/getPopularProducts`,
  getRecentProduct: `${url}/user/getRecentProducts`,
  listCategory: `${url}/category/listCategory`,
  listAllCategory: `${url}/category/listCategoryAndSubCategory`,
  subCategory: `${url}/category/listSubCategory`,
  getFeaturedProduct: `${url}/user/getAllProducts`,
  wishlist: `${url}/user/allWishlist`,
  getAllBanners: `${url}/banner/get-all-banners`,
  followUnfollowUser: `${url}/user/followUnfollowUser/{userId}`,
  sellersProfile: `${url}/product/sellerProfile`,
  viewProduct: `${url}/product/viewProduct`,
  myProductListWithStatus: `${url}/product/myProductListwithStatus`,
  orderHistory: `${url}/order/orderHistory`,
  contactUs: `${url}/contact/createContactUs`,
  getAllAdvertisement: `${url}/advertisement/get-all-advertisement`,
  getAllNotification: `${url}/notification/listNotification`,
  clearAllNotification: `${url}/notification/clearAllNotification`,
  updateAddress: `${url}/addresses/updateAddress`,
  updateProduct: `${url}/product/updateProduct`,
  listProduct: `${url}/product/listProduct`,
  updateProductStatus: `${url}/product/updateProductStatus`,
  deleteData: `${url}/product/deleteProduct`,
  updateProfile: `${url}/user/updateProfile`,
  addToWishlist: `${url}/user/addToWishlist`,
  checkStatus: `${url}/product/checkStatus`,
  listedProduct: `${url}/product/listedProduct`,
  serchProduct: `${url}/product/serchProduct`,
  createReport: `${url}/product/createReport`,
  addProduct: `${url}/product/addProduct`,
  removeWishlist: `${url}/user/removeWishlist`,
  oneToOneChatApi: `${url}/socket/oneToOneChatApi`,
  chatHistory: `${url}/socket/chatHistory`,
  checkoutProduct: `${url}/payment/checkoutProduct`,
  applyCoupon: `${url}/payment/applyCoupon`,
  searchSort: `${url}/product/sortingAndSearchingProduct`,
  updateCheckout: `${url}/payment/useProductCheckOut`,
  getAddressList: `${url}/addresses/getAddresses`,
  checkoutOrder: `${url}/payment/checkoutOrder`,
  termsConditions:`${url}/static/viewStaticContent/{type}`,
  aboutUs:`${url}/static/viewStaticContent/{type}`,
  getMaintenance:`${url}/user/getMaintainanceStatus`,
  getCountry:`${url}/user/getCountry`,
  getstates:`${url}/user/states`,
  getFaq:`${url}/faq/faqList`,
  resportSeller:`${url}/product/createReportSeller`
};

export default ApiConfig;
